<template>
  <!--
    The view for the InstalltionQuestionList-component.
    Used to display the questions within a template.
  -->
  <Portlet
    :title="`${ $t('menu.installationQuestions') }`"
    icon="question"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template
        slot="buttons"
      >
        <button
          class="btn btn-primary float-right mt-2"
          @click="showAddQuestion"
        >
          <font-awesome-icon
            class="mr-2"
            icon="plus"
          />
          <span>{{ $t('add') }}</span>
        </button>
        <div class="clearfix" />
      </template>
      <InstallationQuestionList
        ref="questionList"
        :template-id="templateId"
        @reloadAuditLogs="reloadAuditLogs"
      />
      <DatabaseAuditLogList
        ref="databaseAuditLogList"
        :template-id-for-question="templateId"
        @reload="reloadQuestionsList"
      /> 
    </template>
  </Portlet>
</template>

<script>
export default {
  name: "InstallationQuestions",
  components: {
    InstallationQuestionList: () => import('@/components/Installation/InstallationQuestionList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  props: {
    templateId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      template: {
        name: null,
        installationTypeId: null
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('menu.installationQuestions')
    }
  }, 
  created () {
    this.getTemplate();
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadQuestionsList () {
      this.$refs.questionList.getData();
    },
    getTemplate () {
      this.loading = true;
      this.axios.get(`/Installation/GetQuestionTemplate?templateId=${ this.templateId }`)
        .then((response) => {
          this.template = response.data;
        })
        .finally(() => {this.loading = false;});
    },
    showAddQuestion () {
      this.$refs.questionList.showAddQuestion();
    }
  },
}
</script>
